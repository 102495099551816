const React = require("react");
const Layout = require("@eimerreis/lauterbad-components").Layout;

const socialMediaLinks = {
    Instagram: "https://www.instagram.com/fritzlauterbad/",
    Facebook: "https://www.facebook.com/FritzLauterbad/",
    Mail: "mailto:info@fritz-lauterbad.de",
    Phone: "tel:+497441950990"
}

const logo = "https://a.storyblok.com/f/63567/820x536/363bee296f/fritzeagle.png";

exports.wrapPageElement = ({ element, props }) => {
    return (
        <Layout {...{...props.pageContext, socialMediaLinks, logo}}>
            {element}
        </Layout>
    )
}
